<template>
    <router-view />
</template>
<script>
import accountMixin from '@/mixins/accounts.js'
export default {
    name: 'MemberLayout',

    mixins: [accountMixin],
    data: () => ({
        isAdminMode: false,
    }),
    computed: {
        credentials() {
            const { authorized, userid, isAdmin } = this;
            return { authorized, userid, isAdmin }
        }
    },
    watch: {
        credentials: {
            handler: function (val) {            
                const { authorized } = val;
                authorized && this.$store.dispatch("profile/loadAssets")
            },
            deep: true,
            immediate: true
        }
    }
}
</script>